<template>
  <v-row>
    <v-col cols="6">
      <v-select
        v-model="cadastro.accountType"
        label="Conta"
        :items="['PONTO_ATENDIMENTO']"
        item-value="PONTO_ATENDIMENTO"
        disabled
        outlined
      ></v-select>
    </v-col>

    <v-col cols="6">
      <v-select
        disabled
        label="AC - SENHA DIGITAL - CWB"
        v-model="cadastro.belongsTo"
        outlined
        persistent-hint
      ></v-select>
    </v-col>

    <v-col cols="12" sm="12">
      <v-text-field
        v-model="cadastro.name"
        label="Nome do Ponto de Atendimento (Empresa)"
        :rules="validationRules"
        outlined
      >
      </v-text-field>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        label="CPF/CNPJ"
        v-model="cadastro.cpfcnpj"
        v-mask="['##.###.###/####-##']"
        :rules="validationRules"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="cadastro.email"
        :rules="validationRules"
        outlined
        label="Email"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <vue-tel-input-vuetify
        outlined
        label="Telefone*"
        v-model="cadastro.phoneNumber"
        :rules="validationRules"
        defaultCountry="BR"
        :preferredCountries="['BR', 'AR', 'UY', 'PY']"
        :disabledFetchingCountry="true"
        placeholder=""
      >
      </vue-tel-input-vuetify>
    </v-col>
    <v-col cols="12" sm="6">
      <v-autocomplete
        :rules="validationRules"
        v-model="cadastro.consultor"
        outlined
        label="Responsavel"
        :items="this.allUser"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import api from '../configs/api'
export default {
  name: "RegisterPadrao",
  props: {
    value: Array,
  },
  data: () => ({
    validationRules: [(v) => !!v || "Esse campo é obrigatório"],
  }),
  async created() {
    const { data } = await api.get("user");
    this.allUser = data.map((v) => v.nome);
  },

  computed: {
    cadastro: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
