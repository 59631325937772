import Vue from 'vue'
import App from './App.vue'
import api from './configs/api'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import store from './store'
import VueMask from 'v-mask'


Vue.prototype.$http = api

Vue.config.productionTip = false

Vue.use(VueMask)

Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
