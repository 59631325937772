<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/logobranca.png" contain></v-img>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex justify-center">
          <v-row no-gutters class="align-start">
            <v-col
              cols="12"
              class="login-part d-flex align-center justify-center flex-column"
            >
              <div class="login-wrapper pt-sm-0">
                <v-tabs grow v-model="register" light>
                  <v-tab-item :value="'tab-register'">
                    <v-container>
                      <v-row class="flex-column">
                        <v-col>
                          <p
                            class="login-slogan display-1 text-center font-weight-medium"
                          >
                            Ponto de Atendimento
                          </p>
                        </v-col>
                        <v-col cols="12" class="d-flex my-3 my-sm-8">
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-btn
                            large
                            class="primary"
                            to="/pontoatendimento/new"
                            >Cadastrar</v-btn
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-btn
                            light
                            class="secondary"
                            @click="register = 'tab-query'"
                            >Consultar Cadastro</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item :value="'tab-query'">
                    <v-container>
                      <v-row class="flex-column">
                        <v-col>
                          <p></p>
                          <v-alert v-if="mensagem" dense outlined type="error">
                            {{ mensagem }}
                          </v-alert>
                        </v-col>
                        <v-col>
                          <p class="display-1 text-center font-weight-medium">
                            Consulte seu cadastro
                          </p>
                        </v-col>
                        <v-col>
                          <v-text-field
                            label="CPF/CNPJ"
                            v-model="cpfcnpj"
                            :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                          >
                            <template v-slot:append>
                              <v-fade-transition leave-absolute>
                                <v-progress-circular
                                  v-if="condition"
                                  size="24"
                                  color="info"
                                  indeterminate
                                ></v-progress-circular>
                                />
                              </v-fade-transition>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-space-between">
                          <v-btn @click="consult" class="primary" large>
                            Consultar
                          </v-btn>
                          <v-btn
                            large
                            text
                            class="primary--text"
                            @click="register = 'tab-register'"
                          >
                            Voltar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import api from "@/configs/api";
export default {
  name: "LoginView",
  data: () => ({
    register: "tab-register",
    cpfcnpj: "",
    mensagem: "",
    condition: false,
  }),
  methods: {
    async consult() {
      this.condition = true;
      const noCharacter = this.cpfcnpj.replace(/\D/g, "");
      try {
        const response = await api.get(
          `/account/identification/${noCharacter}`
        );
        if (response.status === 200) {
          this.$router.push(`/pontoatendimento/${noCharacter}`);
        }
      } catch (error) {
        console.error(error);
        this.mensagem = "Error: cpf ou cnpj não encontrado";
      }
      this.condition = false;
    },
  },
};

// const noCharacter = this.cpfcnpj.replace(/\D/g, "");
// this.$router.push(`/formpontoatendimento/${noCharacter}`);
</script>

<style lang="scss">
.container {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 0;
  .main-part {
    width: 100%;
    height: 100vh;
    background: #0e8ce2;
    background: linear-gradient(90deg, #040a40 0%, #0e8ce2 50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    p {
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 50px;
      color: white;
      font-weight: 500;
    }
  }
  .login-part {
    width: 100%;
    height: 100vh;
    background-color: #f6f7ff;
    overflow-y: auto;
    .login-wrapper {
      width: 320px;
      height: auto;
      .login-slogan {
        color: #4a4a4a;
      }
      .v-tabs {
        .v-tab {
          font-size: 18px;
        }
        .v-item-group {
          background-color: #f6f7ff;
        }
      }
    }
  }
}
</style>
