<template>
  <v-app >
    <v-app-bar app color="primary" dark  v-if="notIsLogin">
      <v-toolbar-title>Ponto de atendimento</v-toolbar-title>
      <v-spacer></v-spacer>
     <div>
        <img width="130" src="@/assets/logobranca.png"/>
     </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    notIsLogin() {
      return this.$route.name !== "login"
    }
  }
};
</script>
