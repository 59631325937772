<template>
  <v-container class="elements-page mt-2">
    <v-card elevation="8">
      <v-card-title id="catdCSs">
        <p class="text-h5">CADASTRO PA</p>
      </v-card-title>

      <v-tabs v-model="currentTab" fixed-tabs>
        <v-tab href="#account">
          <v-icon class="mr-3">mdi-shield-account-variant-outline</v-icon>
          Dados Da Conta
        </v-tab>
        <v-tab href="#infoRegister">
          <v-icon class="mr-3">mdi-account-switch</v-icon>
          Informações de Cadastro
        </v-tab>
        <v-tab href="#infoOwner">Informações do Proprietário</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab" class="my-5">
        <v-form ref="form" class="mx-2" lazy-validation>
          <v-tab-item value="account">
            <v-card-text>
              <Register
                :value="cadastro"
                @input="updateCadastro"
                title="Endereço De Cadastro"
              ></Register>
              <Address
                :value="cadastro.address"
                @input="updateAddress"
                title="Endereço De Cadastro"
              ></Address>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="infoRegister">
            <v-card-text>
              <StatusRegister
                :value="cadastro"
                @input="updateCadastro"
              ></StatusRegister>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="infoOwner">
            <v-card-text>
              <InfoOwner
                :value="cadastro.proprietario"
                @input="updateOwner"
              ></InfoOwner>
            </v-card-text>
          </v-tab-item>

          <v-container>
            <v-row class="mb-2 mr-1 ml-1" cols="12" justify="space-between">
              <v-btn color="secundary" to="/">Voltar</v-btn>
              <v-btn color="primary" v-if="cadastro._id" @click="update"
                >Atualizar</v-btn
              >
              <v-btn color="primary" v-else @click="save">Salvar</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import api from "../configs/api";
import Register from "../components/Register.vue";
import Address from "../components/Address.vue";
import StatusRegister from "../components/StatusRegister.vue";
import InfoOwner from "@/components/Owner.vue";
export default {
  name: "PontoAtendimento",

  components: {
    Register,
    Address,
    StatusRegister,
    InfoOwner,
  },

  data: () => ({
    searchText: "",
    validationRules: [(v) => !!v || "Esse campo é obrigatório"],
    currentTab: "account",
    allUser: null,
    cadastro: {
      dataCadastro: "",
      status: "Novo Cadastro",
      proprietario: {},
      address: {
        type: "Principal",
      },
      belongsTo: {
        accountType: "AUTORIDADADE_REGISTRO",
        _id: "61802446db82ed0dd49556f7",
        cpfcnpj: "19.520.630/0001-15",
        name: "AC - SENHA DIGITAL - CWB",
        email: "comercial@senhadigital.com.br",
        phoneNumber: "+55 30272710",
      },
      _id: null,
      accountType: "PONTO_ATENDIMENTO",
    },
    allnomes: null,
  }),
  computed: {
    dataCadastro: {
      get() {
        const D = new Date();
        D.setFullYear(D.getFullYear());
        return D.toISOString().substr(0, 10);
      },
      set(value) {
        this.cadastro.dataCadastro = new Date(value).toLocaleDateString();
      },
    },
  },
  methods: {
    async save() {
      const formValido = this.$refs.form.validate();
      if (!formValido) {
        return false;
      }
      try {
        await api.post("account/", this.cadastro);
        console.log("ok");
      } catch (err) {
        console.log(err);
      }
    },
    async update() {
      try {
        await api.put("account/" + this.cadastro._id, this.cadastro);
        console.log("ok");
      } catch (err) {
        console.log(err);
      }
    },
    async getAccount(id) {
      try {
        const { data } = await api.get("account/identification/" + id);
        this.cadastro = data;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== "new") {
      this.getAccount(this.$route.params.id);
    }
  },
};
</script>

<style scoped>
#catdCSs {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
</style>
