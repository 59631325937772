import axios from 'axios'

var api = axios.create({
    // produção
    // baseURL: 'https://app.sdagenda.com.br/api/',

    // teste
    baseURL: 'http://localhost:3003/api/',
    timeout: 2000,
    headers: {
        'x-access-token': 'mongodb-direct'
    }
})
export default api


// https://dev.sdagenda.com.br/api/account
// headers: 'x-access-token': 'mongodb-direct'
