<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ title }}</h2>
      <v-divider class="mb-3"></v-divider>
    </v-col>
    <v-col cols="12" sm="8">
      <v-text-field
        :rules="validationRules"
        outlined
        v-model="address.address"
        label="Endereço"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-select
        outlined
        v-model="address.type"
        label="Tipo de Endereço"
        :items="['Principal', 'Correspondência']"
      >
      </v-select>
    </v-col>

    <v-col cols="12" sm="2">
      <v-text-field
        outlined
        :rules="validationRules"
        v-model="address.number"
        label="Número"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        outlined
        :rules="validationRules"
        v-model="address.district"
        label="Bairro"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        outlined
        :rules="validationRules"
        v-model="address.cep"
        label="CEP"
        v-mask="['##.###-###']"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="8">
      <v-text-field
        outlined
        :rules="validationRules"
        v-model="address.city"
        label="Cidade"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-autocomplete
        outlined
        :rules="validationRules"
        v-model="address.state"
        label="Estado"
        :items="states"
        item-text="name"
        item-value="code"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { getStates } from "@brazilian-utils/brazilian-utils";

export default {
  name: "AddressRegistre",

  props: {
    value: Array,
    title: String,
  },

  data() {
    return {
      states: getStates(),
      validationRules: [(v) => !!v || "Esse campo é obrigatório"],
    };
  },

  computed: {
    address: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style></style>
