<template>
  <v-row>
    <v-col cols="12" sm="4">
      <v-select
        label="Status"
        v-model="cadastro.status"
        :items="['Novo Cadastro']"
        outlined
        disabled
        persistent-hint
      ></v-select>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field
        type="date"
        prepend-icon="mdi-calendar"
        v-model="cadastro.dataCadastro"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field
        type="text"
        v-model="cadastro.codSiteParceiro"
        outlined
        label="Código do Site Parceiro"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "StatusRegister",
  props: {
    value: Array,
  },
  computed: {
    cadastro: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
