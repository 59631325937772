<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field label="Nome" v-model="proprietario.name" outlined>
      </v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="proprietario.cpfcnpj"
        label="CPF/CNPJ"
        v-mask="['###.###.###-##', '##.###.###/####-##']"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6">
      <vue-tel-input-vuetify
        v-model="proprietario.phone"
        outlined
        label="Telefone*"
        defaultCountry="BR"
        :preferredCountries="['BR', 'AR', 'UY', 'PY']"
        :disabledFetchingCountry="true"
        placeholder=""
      >
      </vue-tel-input-vuetify>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        outlined
        type="email"
        v-model="proprietario.email"
        label="Email"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'OwnerRegister',
    props: {
        value: Array
    },
   computed: {
    proprietario: {
        get() {
            return this.value
        },
        set(newValue){
            this.$emit("input", newValue)
        }
    }
   }
}
</script>
