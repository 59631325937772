import Vue from 'vue'
import VueRouter from 'vue-router'
import pontoatendimento from '../views/pontoatendimento.vue'
// import FormAGR from '../views/FormAGR.vue'
import Login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

  {
    path: '/pontoatendimento',
    name: 'pontoatendimento',
    component: pontoatendimento
  },
  {
    path: '/pontoatendimento/:id',
    component: pontoatendimento
  },
  // {
  //   path: '/formagenteregistro',
  //   name: 'formagenteregistro',
  //   component: FormAGR
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
