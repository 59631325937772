import api from '../configs/api';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuarios: []
  },
  getters: {
  },
  mutations: {
   setUsuarios(state, payload) {
    state.usuarios = payload;
   }
  },
  actions: {
    async allUsers({ commit }) {
      const { data } = await api.get("user");
      commit("setUsuarios", data)
    }
  },
  modules: {
  }
})
